<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">

  <button *ngIf="projectService.isRevitData" class="main-btn" (click)="openPatinejos()">{{'open_patinejos_revit' |
    tr}}</button>
  <p *ngIf="!projectService.isRevitData">Herramienta de creación solo está disponible desde Revit</p>

  <div class="page-section-div">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let patinejo of montantes ; let i = index" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{patinejo.tipoPatinejo.nombre}} - {{patinejo.nombre}}
          </mat-panel-title>
          <mat-panel-description>
            <button *ngIf="projectService.isRevitData" mat-icon-button (click)="searchRoom(patinejo)">
              <mat-icon class="mat-icon-rtl-mirror">
                search
              </mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>  