import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService,TipoPatinejo } from '../services/project.service';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { Patinejo, TipoPatinejoMontante } from 'src/app/Shared/modals/patinejo.interface';

@Component({
  selector: 'app-patinejos',
  templateUrl: './patinejos.component.html',
  styleUrls: ['./patinejos.component.css'],
})



export class PatinejosComponent implements OnInit {

  patinejos: Patinejo[] = [];
  montantes: TipoPatinejoMontante[] = [];
  // tipoPatinejoMontanteSeleccionado: TipoPatinejoMontante[][] = [];
  isLoader:boolean = false;

  constructor(public auth: AuthService, private route: ActivatedRoute, private http: HttpClient, public projectService: ProjectService,private toastr:ToastrService) {
  }

  async ngOnInit() {
    this.isLoader = false;
    this.projectService.setProjectId(this.route.snapshot.paramMap.get('id'));
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        this.http.get<Patinejo[]>(URL_API + '/proyectos/patinejos/' + this.projectService.projectId.getValue(), httpOptions).subscribe(async (result) => {
          this.patinejos = await result;
          if(this.patinejos.length > 0){
            this.patinejos.forEach(patinejo => {
              patinejo.tipoPatinejoMontante.forEach(tipoMontante => {
                tipoMontante.idElement = patinejo.idElement;
                this.montantes.push(tipoMontante);
              });
            });
          }
          console.log('agujeros:',this.patinejos)
        });
      }else{
        this.isLoader = false;
      }
    })
  }

  openPatinejos(){
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'openPatinejos' }));
  }

  // tipoPatinejoChange(patinejo: Patinejo,ob:any,j:number) {
  //   let id = ob;
  //   const a = this.auth.idTokenClaims$;
  //   a.subscribe(accessToken => {
  //     if (accessToken && accessToken != undefined) {

  //       const httpOptions = {
  //         headers: new HttpHeaders({
  //           'Authorization': 'Bearer ' + accessToken.__raw,
  //           'Access-Control-Allow-Origin': '*',
  //         })
  //       };
  //       this.http.get<TipoPatinejoMontante[]>(URL_API + '/patinejos/tipomontantes/' + id, httpOptions).subscribe(async (result:any) => {
  //         console.log('reslt on tipoPatinejoChange',result);
  //         this.tipoPatinejoMontanteSeleccionado[j] = result;
  //         if(result.length===1){
  //           const IdTipoPatinejoMontante =  result[0].id
  //           this.tipoMontanteChange(patinejo,IdTipoPatinejoMontante,j)
  //         }
  //       });
  //     }
  //   });
  // }
  searchRoom(data) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': data }));
  }
  
  // tipoMontanteChange(patinejo: Patinejo, ob:any,tipoIndex?:number) {
  //   let idsHabitaciones = [];
  //   let idsTiposPatinejosMontantes = [];
  //   let id = ob;
  //   console.log('id:',id);
  //   console.log('patinejo.tipoPatinejoMontante:',patinejo.tipoPatinejoMontante)
  //   const a = this.auth.idTokenClaims$;
  //   a.subscribe(accessToken => {
  //     if (accessToken && accessToken != undefined) {
  //       const httpOptions = {
  //         headers: new HttpHeaders({
  //           'Authorization': 'Bearer ' + accessToken.__raw,
  //           'Access-Control-Allow-Origin': '*',
  //           'Content-Type': 'application/json'
  //         })
  //       };
  //       idsHabitaciones.push(patinejo.id);
  //       patinejo.hijos.forEach(hijo => {
  //         idsHabitaciones.push(hijo.id);
  //       });
  //       if(patinejo.tipoPatinejoMontante.length){
  //         patinejo.tipoPatinejoMontante.forEach((tipo , index)  =>{
  //           if(tipo.id === '' || index ===tipoIndex){
  //             idsTiposPatinejosMontantes.push(id);
  //             debugger
  //           }else{
  //             idsTiposPatinejosMontantes.push(tipo.id);
  //             debugger
  //           }
  //         })  
  //       }
  //       const dataToSerialize = {
  //         IdProyecto: this.projectService.projectId,
  //         idHabitaciones:idsHabitaciones,
  //         idTiposPatinejosMontantes: idsTiposPatinejosMontantes
  //       };
  //       this.http.post<any>(URL_API + '/patinejos/tipomontantehabitacion', JSON.stringify(dataToSerialize), httpOptions).subscribe(result => {
  //         console.log('Montante Change result 2:',result)
  //         idsHabitaciones = [];
  //         idsTiposPatinejosMontantes = [];
  //       });
  
  //     }
  //   });
  // }
  // tipoPatinejoMontante:TipoPatinejoMontante;
  // addInstaltion(patinejo: Patinejo) {
  //   if (!patinejo.tipoPatinejoMontante) {
  //     patinejo.tipoPatinejoMontante = [];
  //   }
  //   this.tipoPatinejoMontante = {
  //     id: '',
  //     IdTipoPatinejo: '',
  //     nombre: '',
  //     tipoPatinejo: { id: '', nombre: '' }
  //   };
  //   patinejo.tipoPatinejoMontante.push(this.tipoPatinejoMontante);
  // }

  // removeInstaltion(patinejo:Patinejo,idMontante:string) {
  //   let idHabitaciones = []
  //   let idMontantes = []
  //   idHabitaciones.push(patinejo.id)
  //   patinejo.tipoPatinejoMontante.forEach(tipo =>{
  //     if(tipo.id != idMontante){
  //       idMontantes.push(tipo.id)
  //     }
  //   })
  //   let payLoadData = {
  //     idProyecto: this.projectService.projectId,
  //     idHabitaciones: idHabitaciones,
  //     idTiposPatinejosMontantes: idMontantes
  //   }
  //   const a = this.auth.idTokenClaims$;
  //   a.subscribe(accessToken => {
  //     if (accessToken && accessToken != undefined) {

  //       const httpOptions = {
  //         headers: new HttpHeaders({
  //           'Authorization': 'Bearer ' + accessToken.__raw,
  //           'Access-Control-Allow-Origin': '*',
  //         }),
  //       };
  //       this.http.post(URL_API + '/patinejos/tipomontantehabitacion', payLoadData,httpOptions).subscribe(async (result:any) => {
  //         this.toastr.success('tipo de instalación eliminado con éxito');
  //         window.location.reload()
  //       });
  //     }
  //   });
  // }

}
